import React from 'react';
import { func } from 'prop-types';

import LoadingWrapper from '#components/LoadingWrapper';
import { useMount } from '#hooks/useMount';

function ErrorFallback({ resetError }) {
  useMount(() => {
    resetError?.();
  });
  return <LoadingWrapper loading />;
}

ErrorFallback.propTypes = {
  resetError: func
};

export default ErrorFallback;
