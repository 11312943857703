import { replace } from 'connected-react-router';
import { createTypes, completeTypes, withPostSuccess, withPostFailure } from 'redux-recompose';

import UsersServices from '#services/Users/service';
import { isIncorrectPassError } from '#utils/errors';

import { CODE_STATUS, TARGETS } from './constants';

export const actions = createTypes(
  completeTypes({
    primaryActions: [
      'CREATE_USER',
      'UPDATE_USER_BY_ID',
      'DELETE_USER_BY_ID',
      'GET_USER_BY_ID',
      'GET_USERS',
      'SEND_CODE',
      'VALIDATE_CODE',
      'FIND_ADMIN'
    ],
    ignoredActions: []
  }),
  '@@USERS'
);

const actionCreators = {
  createUser: ({ user, getCloseUrl }) => ({
    type: actions.CREATE_USER,
    target: TARGETS.CREATE_USER,
    payload: user,
    service: UsersServices.createUser,
    injections: [
      withPostSuccess(dispatch => {
        dispatch(replace(getCloseUrl()));
      })
    ]
  }),
  updateUserById: (id, updatedFields) => ({
    type: actions.UPDATE_USER,
    target: TARGETS.UPDATE_USER,
    payload: { id, updatedFields },
    service: UsersServices.updateUserById
  }),
  deleteUserById: id => ({
    type: actions.DELETE_USER_BY_ID,
    target: TARGETS.DELETE_USER,
    service: UsersServices.deleteUserById,
    payload: id
  }),
  getUserById: payload => ({
    payload,
    type: actions.GET_USER_BY_ID,
    target: TARGETS.SELECTED_USER,
    service: UsersServices.getUserById
  }),
  getUsers: query => ({
    type: actions.GET_USERS,
    target: TARGETS.SELECTED_USERS,
    service: UsersServices.getUsers,
    payload: query
  }),
  sendCode: (phone, successCallback, failureCallback) => ({
    type: actions.SEND_CODE,
    target: TARGETS.SENT_CODE,
    payload: phone,
    service: UsersServices.sendCode,
    injections: [withPostSuccess(successCallback), withPostFailure(failureCallback)]
  }),
  validateCode: ({ phone, code, successCallback, failureCallback }) => ({
    type: actions.VALIDATE_CODE,
    target: TARGETS.CODE_VALIDATION,
    payload: { phone, code },
    service: UsersServices.validateCode,
    injections: [
      withPostSuccess((dispatch, response) => {
        const { status } = response.data;
        if (status === CODE_STATUS.APPROVED) {
          successCallback?.();
        } else {
          failureCallback?.();
        }
      }),
      withPostFailure(() => {
        failureCallback?.();
      })
    ]
  }),
  findAdminByPhone: (payload, successCallback, failureCallback, incorrectPassFailure) => ({
    type: actions.FIND_ADMIN,
    target: TARGETS.FIND_ADMIN,
    payload,
    service: UsersServices.findAdminByPhone,
    injections: [
      withPostSuccess(successCallback),
      withPostFailure((dispatch, response) =>
        isIncorrectPassError(response.data[0]) ? incorrectPassFailure() : failureCallback()
      )
    ]
  })
};

export default actionCreators;
