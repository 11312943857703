import Immutable from 'seamless-immutable';
import { createReducer, completeState, completeReducer } from 'redux-recompose';

import { actions } from './actions';

const initialState = {
  onInitialLoad: true,
  signIn: null,
  currentUser: {}
};

const completedState = completeState(initialState);

const reducerDescription = {
  primaryActions: [actions.SIGN_IN, actions.GET_CURRENT_USER, actions.LOG_OUT, actions.SIGN_IN_ADMIN]
};

const reducer = createReducer(new Immutable(completedState), completeReducer(reducerDescription));

export default reducer;
