import { ConnectedRouter } from 'connected-react-router';
import React from 'react';

import { history } from '#redux/store';

import Routes from './components/ConnectedRoutes';

const AppRoutesContainer = () => (
  <ConnectedRouter history={history}>
    <Routes />
  </ConnectedRouter>
);

export default AppRoutesContainer;
