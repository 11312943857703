import { create } from 'apisauce';
import { CamelcaseSerializer, SnakecaseSerializer } from 'cerealizr';
import { NON_SERIALIZABLE_URLS } from '#constants/urls';

const notSerializableUrl = url => NON_SERIALIZABLE_URLS.includes(url)

export const createApiWithURL = baseURL =>
  create({
    baseURL,
    timeout: 15000
  });

const api = create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 15000
});

export const headers = {
  AUTHORIZATION: 'Authorization'
};

export const setAuthHeader = token => 
  api.setHeader(headers.AUTHORIZATION, `Bearer ${token}`);


export const removeAuthHeader = () => api.deleteHeader(headers.AUTHORIZATION);

export default api;
