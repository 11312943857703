export const TARGETS = {
  CREATE_USER: 'createUser',
  UPDATE_USER: 'updateUser',
  DELETE_USER: 'deleteUser',
  SELECTED_USER: 'selectedUser',
  SELECTED_USERS: 'selectedUsers',
  SENT_CODE: 'sentCode',
  CODE_VALIDATION: 'codeValidation',
  FIND_ADMIN: 'findAdmin'
};

export const CODE_STATUS = {
  APPROVED: 'approved',
  PENDING: 'pending'
};
