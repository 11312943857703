export const ROLES = {
  USER: 'user',
  ADMIN: 'admin'
};

export const ADMIN_ROLES = {
  BASIC: 'basic',
  ADMIN: 'admin'
};

export const PATHNAME_REDIRECT_PARAM = 'redirectTo';
