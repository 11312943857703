import React, { Component } from 'react';
import * as Sentry from '@sentry/react';
import { func } from 'prop-types';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const { onError } = this.props;
    Sentry.captureException(error);
    onError?.(error, errorInfo);
  }

  resetError = () => {
    const { onReset } = this.props;
    onReset?.();
    this.setState({ hasError: false });
  };

  render() {
    const { children, FallbackComponent } = this.props;
    const { hasError } = this.state;
    return hasError ? <FallbackComponent resetError={this.resetError} /> : children;
  }
}

ErrorBoundary.propTypes = {
  FallbackComponent: func,
  onError: func,
  onReset: func
};

export default ErrorBoundary;
