import { bool, shape, string } from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useLocation, withRouter } from 'react-router';
import { useMemo } from 'react';

import { useRedirect } from '#hooks/useRedirect';
import Helmet from '#components/Helmet';
import LocalStorage from '#services/LocalStorageService';
import { getCurrentRouteRedirect } from '#models/routes';
import { ROUTES } from '#constants/routes';
import ErrorBoundary from '#components/ErrorBoundary';
import ErrorFallback from '#components/ErrorFallback';

const AuthenticatedRoute = ({ title, description, path, component: Component, componentProps, ...props }) => {
  const handleRedirect = useRedirect();
  const tokenManager = LocalStorage.getTokenManager();
  const { pathname, search } = useLocation();
  const routeToRedirect = useMemo(
    () =>
      getCurrentRouteRedirect({
        pathname,
        search,
        tokenManager
      }),
    [tokenManager, pathname]
  );
  return routeToRedirect ? (
    <Redirect to={routeToRedirect} />
  ) : (
    <>
      <Helmet title={title} description={description} />
      <ErrorBoundary FallbackComponent={ErrorFallback} onReset={handleRedirect(ROUTES.ERROR.path)}>
        <Route path={path} render={() => <Component {...componentProps} />} {...props} />
      </ErrorBoundary>
    </>
  );
};

AuthenticatedRoute.propTypes = {
  path: string.isRequired,
  component: shape,
  componentProps: shape,
  description: string,
  publicRoute: bool,
  supportedRoles: shape,
  title: string
};

export default withRouter(AuthenticatedRoute);
