import api from '#config/api';
import { getQueryString } from '#utils/parseUrl';

const createUser = user => api.post('/users', user);
const getUserById = id => api.get(`/users/${id}`);
const getUsers = query => api.get(`/users${getQueryString({ query })}`);
const updateUserById = ({ id, ...otherUserFields }) => api.put(`/admin/users/${id}`, { ...otherUserFields });
const deleteUserById = id => api.delete(`/admin/users/${id}`);
const sendCode = phone => api.post('/twilio_sendOTP', phone);
const validateCode = ({ phone, code }) => api.post('/twilio_verifyOTP', { phone, code });
const findAdminByPhone = ({ phone, password }) => api.post('/admin/check', { phone, password });

export default {
  createUser,
  getUserById,
  getUsers,
  updateUserById,
  deleteUserById,
  sendCode,
  validateCode,
  findAdminByPhone
};
