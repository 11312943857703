import { completeTypes, createTypes, withPostSuccess } from 'redux-recompose';
import { push } from 'connected-react-router';

import { removeAuthHeader, setAuthHeader } from '#config/api';
import * as AuthService from '#services/AuthService';
import LocalStorage from '#services/LocalStorageService';
import { DEFAULT_ROUTE_PATH, ROUTES } from '#constants/routes';
import { ROLES } from '#constants/users';
import { deleteCookie } from '#utils/cookies';

export const actions = createTypes(
  completeTypes({ primaryActions: ['SIGN_IN', 'GET_CURRENT_USER', 'LOG_OUT', 'SIGN_IN_ADMIN'] }),
  '@@AUTH'
);

const targets = {
  SIGN_IN: 'signIn',
  CURRENT_USER: 'currentUser',
  SIGN_IN_ADMIN: 'signInAdmin'
};

export const actionCreators = {
  getCurrentUser: () => ({
    type: actions.GET_CURRENT_USER,
    target: targets.CURRENT_USER,
    service: AuthService.getCurrentUser,
    injections: [
      withPostSuccess((dispatch, response) => {
        const tokenManager = LocalStorage.getTokenManager();
        LocalStorage.setTokenManager({
          ...tokenManager,
          role: response?.data?.role
        });
        const loginRedirectUrl = LocalStorage.getLoginRedirectUrl();
        if (loginRedirectUrl) {
          dispatch(push(loginRedirectUrl));
          LocalStorage.removeLoginRedirectUrl();
        }
      })
    ]
  }),
  signInAdmin: phone => ({
    type: actions.SIGN_IN_ADMIN,
    target: targets.SIGN_IN_ADMIN,
    payload: phone,
    service: AuthService.signInAdmin,
    injections: [
      withPostSuccess((dispatch, response) => {
        LocalStorage.setTokenManager(response.data);
        LocalStorage.setLoginRedirectUrl(DEFAULT_ROUTE_PATH[ROLES.ADMIN]);
        const token = response?.data?.token;
        setAuthHeader(token);
        dispatch(push(ROUTES.HOME.path));
      })
    ]
  }),
  logout: oAuthLogoutCallback => dispatch => {
    removeAuthHeader();
    LocalStorage.removeTokenManager();
    deleteCookie('token');
    dispatch({
      type: actions.LOG_OUT,
      target: targets.CURRENT_USER
    });
    oAuthLogoutCallback?.();
    if (!oAuthLogoutCallback) {
      dispatch(push(ROUTES.LOGIN.path));
    }
  }
};

export default actionCreators;
