import { convertDecimal, onlyNumbers, addHours } from '@Zerf-dev/utils';

export const isLogged = expirationTime => {
  const currentDate = new Date();
  return expirationTime && addHours(currentDate, expirationTime) > currentDate;
};

export const hasAccess = (tokenManager, supportedRoles) =>
  tokenManager && supportedRoles.includes(tokenManager.role);

export const mapExpirationTime = string => convertDecimal(onlyNumbers(string), 10);
