import { useMount } from 'hooks/useMount';

export const useCalculateVh = () => {
  const calculateVh = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  useMount(() => {
    calculateVh();
    window.addEventListener('resize', calculateVh);
  });
};
