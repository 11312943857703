import { DEFAULT_ROUTE_PATH, ROUTES } from '#constants/routes';
import { getCurrentRoute } from '#utils/routes';

import { hasAccess, isLogged, mapExpirationTime } from './session';

const getLoginPath = (pathname, search) => {
  const searchQueryParam = search ? `&${search.substring(1)}` : '';
  return {
    pathname: ROUTES.LOGIN.path,
    search: pathname === '/' ? '' : `?redirectTo=${pathname}${searchQueryParam}`
  };
};

export const mapRedirects = (tokenManager, pathname, search) => {
  const defaultPath = DEFAULT_ROUTE_PATH[tokenManager?.role];
  const login = getLoginPath(pathname, search);

  return { login, defaultPath };
};

export const getLoggedUserRedirect = ({ privateRoute, access, defaultPath }) =>
  !(privateRoute && access) && defaultPath;

export const getCurrentRouteRedirect = ({ tokenManager, pathname, search }) => {
  const { supportedRoles = [], publicRoute, privateRoute = !publicRoute } = getCurrentRoute(pathname) || {};

  const expirationTime = tokenManager && mapExpirationTime(tokenManager.expirationTime);
  const logged = isLogged(expirationTime);
  const access = publicRoute || hasAccess(tokenManager, supportedRoles);
  const { login, defaultPath } = mapRedirects(tokenManager, pathname, search);

  return logged ? getLoggedUserRedirect({ privateRoute, access, defaultPath }) : !publicRoute && login;
};
