import { lazy } from 'react';

import { ROLES } from './users';

const Home = lazy(() => import('#screens/Home'));
const Login = lazy(() => import('#screens/Login'));
const ErrorScreen = lazy(() => import('#screens/ErrorScreen'));

export const ROUTES = {
  HOME: {
    path: '/',
    basePath: '',
    exact: true,
    supportedRoles: [ROLES.ADMIN],
    component: Home
  },
  LOGIN: {
    path: '/login',
    basePath: '',
    publicRoute: true,
    supportedRoles: [ROLES.USER, ROLES.ADMIN],
    exact: false,
    component: Login
  },
  ERROR: {
    path: '/error',
    basePath: '',
    publicRoute: true,
    supportedRoles: [ROLES.USER, ROLES.ADMIN],
    exact: false,
    component: ErrorScreen
  }
};

export const DEFAULT_ROUTE_PATH = {
  [ROLES.USER]: ROUTES.LOGIN.path,
  [ROLES.ADMIN]: ROUTES.HOME.path
};
