import i18next from 'i18next';

i18next.addResources('es', 'Login', {
  title: 'Ingresar al administrador de Cora',
  continueWith: 'Continuar con {{option}}',
  continueWithEmail: 'Continuar con correo electrónico',
  withoutAccount: '¿No tenés una cuenta?',
  or: 'o',
  registerHere: 'Registrate aquí',
  logInTitle: 'Iniciá sesión',
  phone: 'Teléfono',
  password: 'Contraseña',
  forgotYourPassword: '¿Olvidaste tu contraseña?',
  logIn: 'Iniciar sesión',
  inputPhone: 'Please enter your phone',
  sms: 'Verificar teléfono',
  blankPhone: 'Por favor ingresar un teléfono',
  blankPassword: 'Por favor ingresar una contraseña',
  invalidPhone: 'Por favor ingresar un teléfono válido',
  verifyCode: 'Ingresá el código enviado a {{phone}}',
  code: 'Código',
  blankCode: 'Por favor ingresar un código',
  invalidCode: 'Código incorrecto',
  enter: 'Ingresar',
  signOut: 'Cerrar sesión',
  incorrectPass: 'Contraseña incorrecta'
});
