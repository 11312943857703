import React, { Suspense } from 'react';

import AuthenticatedRoute from '../AuthenticatedRoute';

import Loading from '#components/LoadingWrapper/components/Loading';
import { ROUTES } from '#constants/routes';

import styles from './styles.module.scss';
import { useCalculateVh } from './hooks';

const Routes = () => {
  useCalculateVh();
  return (
    <div className={styles.container}>
      <Suspense fallback={Loading}>
        {Object.values(ROUTES).map(({ path, ...routeProps }) => (
          <AuthenticatedRoute path={path} key={path} {...routeProps} />
        ))}
      </Suspense>
    </div>
  );
};

export default Routes;
