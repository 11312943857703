import immutable from 'seamless-immutable';
import { createReducer, completeReducer, completeState } from 'redux-recompose';

import { actions } from './actions';

const defaultState = completeState({
  createUser: null,
  updateUser: null,
  deleteUser: null,
  selectedUser: null,
  selectedUsers: []
});

const reducerDescription = {
  primaryActions: [
    actions.CREATE_USER,
    actions.UPDATE_USER_BY_ID,
    actions.DELETE_USER_BY_ID,
    actions.GET_USER_BY_ID,
    actions.GET_USERS,
    actions.SEND_CODE,
    actions.VALIDATE_CODE
  ]
};

const reducer = createReducer(immutable(defaultState), completeReducer(reducerDescription));
export default reducer;
